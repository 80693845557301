<template>
  <flatpickr
    v-model="dateRange"
    name="date_range"
    :config="dateRangeConfig"
    @on-change="dateRangeChanged"
    placeholder="Date Range"
    class="form-control date-range flatpickr-input"
  />
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: ['value'],

  data () {
    return {
      startDate: null,
      endDate: null,
      dateRangeConfig: {
        altInput: true,
        altFormat: 'd/m/Y',
        mode: 'range',
        showMonths: 2
      }
    }
  },

  computed: {
    dateRange: {
      get () {
        if (this.startDate === null || this.endDate === null) {
          return null
        }

        return [this.startDate, this.endDate]
      },

      set (value) {
        //
      }
    }
  },

  created () {
    if (this.value) {
      const { startDate, endDate } = this.value

      this.startDate = startDate
      this.endDate = endDate
    }
  },

  methods: {
    dateRangeChanged (dates) {
      if (dates.length === 2) {
        // returns local time, not UTC
        [this.startDate, this.endDate] = dates.map(date => dayjs(date).format('YYYY-MM-DD'))
      } else if (dates.length === 0) {
        this.startDate = null
        this.endDate = null
      }

      const { startDate, endDate } = this

      this.$emit('input', this.dateRange ? { startDate, endDate } : null)
    }
  }
}
</script>
