<template>
  <fragment>
    <app-header title="Reports">
      <template #actions>
      </template>
    </app-header>

    <div class="container container-fluid mt-4 mb-4">
      <div class="card card-report">
          <div class="row gx-0">
              <div class="col-md-4 col-xl-3 col-select">

                  <nav class="navbar navbar-expand-md navbar-light">
                      <div class="container-fluid flex-wrap">

                          <div class="nav-heading">
                              <div class="row">
                                  <div class="col d-flex align-items-center">
                                      <h2>Select report</h2>
                                  </div>
                              </div><!-- /.row -->
                          </div>

                          <div class="collapse navbar-collapse" id="navbarReportSelect">
                              <ul class="nav navbar-nav flex-column" role="tablist">
                                  <li class="nav-item" role="presentation">
                                      <router-link
                                        class="nav-link"
                                        id="tab-screen-status"
                                        type="button"
                                        role="tab"
                                        aria-controls="screen-status"
                                        aria-selected="true"
                                        :to="{ name: 'reports.screen' }"
                                        exact
                                      >
                                          Screen Status
                                      </router-link>
                                  </li>
                                  <li class="nav-item" role="presentation">
                                      <router-link
                                        class="nav-link"
                                        id="tab-users"
                                        type="button"
                                        role="tab"
                                        aria-controls="users"
                                        aria-selected="true"
                                        :to="{ name: 'reports.user' }"
                                        exact
                                      >
                                          Users
                                      </router-link>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </nav><!-- /.navbar -->

              </div>
              <div class="col col-configure tab-content tab-content-reports">

                  <div
                    class="tab-pane fade show active"
                    role="tabpanel"
                    aria-labelledby="tab-screen-status"
                  >

                    <router-view />

                  </div>

              </div>
          </div><!-- /.row -->
      </div><!-- /.card -->
    </div><!-- /.container -->

  </fragment>

</template>

<script>

export default {
}
</script>
