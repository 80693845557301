<template>
  <fragment>
    <div class="card-body">
        <h2>Users</h2>

        <p class="text-muted mb-0">Export a list of users from the system either from all media owners or specific media owners.</p>
    </div>

    <ul class="filters">
        <li>
            <div class="row">
                <label class="col-lg-3 col-form-label">Media owner</label>
                <div class="col-lg-9">
                    <multiselect
                        class="multiselect-multiple"
                        multiple
                        v-model="filters.mediaOwners"
                        placeholder="Select"
                        :preselect-first="false"
                        :close-on-select="false"
                        :show-labels="false"
                        label="name"
                        track-by="id"
                        :options="mediaOwners"
                    />
                </div>
            </div><!-- /.row -->
        </li>
        <li>
            <div class="row">
                <label class="col-lg-3 col-form-label">Media owner tags</label>
                <div class="col-lg-9">
                    <multiselect
                        class="multiselect-multiple"
                        multiple
                        v-model="filters.mediaOwnerTags"
                        placeholder="Select"
                        :preselect-first="false"
                        :close-on-select="false"
                        :show-labels="false"
                        label="name"
                        track-by="id"
                        :options="mediaOwnerTags"
                    />
                </div>
            </div><!-- /.row -->
        </li>
    </ul><!-- /.filters -->

    <div class="card-body border-top text-end mt-auto">
        <a @click="handleGenerateReport" class="btn btn-primary">Download</a>
    </div>

  </fragment>
</template>

<script>
import { toast } from '@chameleon/chameleon-vue'
import { http } from '@/utils'
import { get, call } from 'vuex-pathify'
import dayjs from 'dayjs'

export default {
  data () {
    return {
      filters: {
        mediaOwners: [],
        mediaOwnerTags: [],
      }
    }
  },

  async created () {
    try {
      await this.getMediaOwners()
      await this.getMediaOwnerTags()
    } catch (e) {
      toast('We encountered an error. We have been alerted and will investigate.', 'danger')
      throw e
    }
  },

  computed: {
    mediaOwners: get('lists/mediaOwners/mediaOwners'),
    mediaOwnerTags: get('lists/mediaOwnerTags/mediaOwnerTags'),
  },

  methods: {
    getMediaOwners: call('lists/mediaOwners/getMediaOwners'),
    getMediaOwnerTags: call('lists/mediaOwnerTags/getMediaOwnerTags'),

    handleGenerateReport () {
      const params = {
        mediaOwners: this.filters.mediaOwners.map(mediaOwner => mediaOwner.id),
        mediaOwnerTags: this.filters.mediaOwnerTags.map(mediaOwnerTag => mediaOwnerTag.id),
      }
      return http
        .get('reports/users', {
          responseType: 'blob',
          headers: { accept: 'application/json, text/csv' },
          params
        })
        .then(response => {
          const filename = `BigScreenVideo-users-${dayjs().format('YYYY-MMMM-DD')}-${dayjs().format('HH-mm')}.csv`
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          return response
        })
    }
  }
}
</script>
